import { fetcher } from "@/utils/api"

export const getContracts = () => {
  return fetcher("/contracts")
}

export const getContractsByTemplate = (template) => {
  return fetcher(`/contracts/templates/${template}`)
}

export const signContracts = (uid) => {
  return fetcher(`/contracts/${uid}/sign`)
}

export const createContract = ({
  project,
  parties = [],
  template = "",
  customer,
  content,
  variables,
}) => {
  return fetcher("/contracts", {
    method: "POST",
    body: JSON.stringify({
      project,
      parties,
      content,
      template,
      customer,
      variables,
    }),
  })
}
